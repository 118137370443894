module.exports = new Promise(resolve => {
        // This part depends on how you plan on hosting and versioning your federated modules
        const remoteUrl = window?.__CLIENT_ENV_VARS__.REACT_APP_MFE_CONSENT_REMOTE_URL
        const script = document.createElement('script')
        script.src = remoteUrl
        script.onload = () => {
          // the injected script has loaded and is available on window
          // we can now resolve this Promise
          const proxy = {
            get: (request) => window.clp_consent_mfe.get(request),
            init: (arg) => {
              try {
                return window.clp_consent_mfe.init(arg)
              } catch(e) {
                console.log('remote container already initialized')
              }
            }
          }
          resolve(proxy)
        }
        // handle no resolution of remoteUrl
        script.onerror = () => {
          const proxy = {
            get: (request) => {
              return Promise.resolve()
            },
            init: (arg) => {
              return
            }
          }
          resolve(proxy)
        }
        // inject this script with the src set to the versioned remoteEntry.js
        document.head.appendChild(script);
      })
      ;